import React from 'react'
import Layout from '../components/layout';
import {graphql} from 'gatsby'
import Img from "gatsby-image";


import Feature from "../components/feature";
import ComingSoon from '../components/coming-soon';

import FaTags from "../img/font-awesome/light/tags.svg";
import FaOrganize from "../img/font-awesome/light/images.svg";
import FaSearch from "../img/font-awesome/light/search.svg";
import FaZip from "../img/font-awesome/light/file-archive.svg";
import FaTransfer from "../img/font-awesome/light/person-carry.svg";
import FaConvert from "../img/font-awesome/light/exchange.svg";

import DownloadButton from "../components/download-button";
import HeroBackground from "../components/hero-background";


export default class IndexPage extends React.Component {

    render()
    {
        return (
            <Layout lang={'en'}
                    title={'2stitch Organizer - Collect, view and organize embroidery files'}
                    description={'Free embroidery design viewer for Mac and Windows. Supports Brother (PES), Tajima (DST), Husquarna (HUS/VIP), Janome (JEF/SEW) and more.'}
                    translated={{de:'/de/'}}
                    location={this.props.location.pathname}
            >

                <div className={'leading-normal text-lg'}>
                    <HeroBackground img={this.props.data.heroImage.childImageSharp.fluid}>
                        <div className="container mx-auto text-center">
                            <h1 className="text-xl md:text-5xl text-blue-50 font-medium mb-3 ">2stitch
                                Organizer</h1>
                            <p className="text-md md:text-3xl text-blue-50">
                                Collect, view and organize your embroidery designs.<br/>
                                Free for Windows and macOS.
                            </p>

                            <DownloadButton linkMac={'/download-organizer-mac/'}
                                            linkWindows={'/download-organizer-windows/'}/>

                        </div>
                    </HeroBackground>


                    <div className="bg-blue-500 text-blue-50 pb-12">
                        <div className={"container mx-auto"}>
                            <div className="text-center">
                                <div className="row">
                                    <div className="col-12 md:col-4 mt-12 ">
                                        <Feature title={"See the designs on your computer"} icon={<FaOrganize
                                            className={'svg-inline--fa fa-2x fill-current text-blue-200'}/>}>
                                            2stitch Organizer lets your preview your designs on your computer,
                                            including information such as size, number of stitches and required
                                            thread length.
                                        </Feature>
                                    </div>

                                    <div className="col-12 md:col-4 mt-12 ">
                                        <Feature title={"Find the designs you need"} icon={<FaSearch
                                            className={'svg-inline--fa fa-2x fill-current text-blue-200'}/>}>
                                            Search your designs by size, thread colors, keywords,
                                            description or file name.
                                        </Feature>
                                    </div>

                                    <div className="col-12 md:col-4 mt-12 ">
                                        <Feature title={"Add Tags"} icon={<FaTags
                                            className={'svg-inline--fa fa-2x fill-current text-blue-200'}/>}>
                                            Group your designs by content, type or whatever category your want: Flowers,
                                            Vintage,
                                            Appliqué, Monograms, &hellip;

                                        </Feature>
                                    </div>
                                    <div className="col-12 md:col-4 mt-12 ">
                                        <Feature title={"Easily work with zip files"} icon={<FaZip
                                            className={'svg-inline--fa fa-2x fill-current text-blue-200'}/>}>
                                            No need to extract zip files anymore. 2stitch Organizer
                                            shows all designs in these archive files.
                                            <br/><ComingSoon/>
                                        </Feature>
                                    </div>
                                    <div className="col-12 md:col-4 mt-12 ">
                                        <Feature title={"Transfer to your embroidery machine"} icon={<FaTransfer
                                            className={'svg-inline--fa fa-2x fill-current text-blue-200'}/>}>
                                            Transfer your selected emboidery files to your USB stick with a single
                                            click.
                                            Or use the new wireless USB stick.
                                        </Feature>
                                    </div>
                                    <div className="col-12 md:col-4 mt-12 ">
                                        <Feature title={"Convert formats"} icon={<FaConvert
                                            className={'svg-inline--fa fa-2x fill-current text-blue-200'}/>}>
                                            Got a design that you like, but it does not work on your machine?
                                            Simply convert it to the format you need.
                                            <br/><ComingSoon/>
                                        </Feature>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className={''}>

                        <div className="container mx-auto pt-12 pb-4 ">

                            <div className="section-header">
                                <h2>So, what does it look like?</h2>
                                <p>
                                    Our focus is to provide you with a clean and easy to use piece of software.
                                </p>
                            </div>

                            <div className="container mx-auto">

                                <Img fluid={this.props.data.screenShotImage.childImageSharp.fluid}/>

                            </div>
                        </div>
                    </div>

                    <div className="bg-blue-50">

                        <div className="container mx-auto pt-12 pb-12">

                            <div className="section-header">
                                <h2>Works on your computer and with your embroidery machine</h2>
                                <p>
                                    Runs directly on Windows and MacOS.<br/>
                                    Instant download. Free.<br/>
                                </p>
                            </div>

                            <div className="mt-12">
                                <h3>Supported embroidery machine brands</h3>
                            </div>

                            <p>
                                2stitch Organizer works with the following file formats and
                                machine brands:
                            </p>

                            <div className="row mt-4">


                                <div className={'col-12 lg:col-4'}>
                                    <table className="table-vertical">
                                        <tbody>
                                        <tr>
                                            <td>Poem, Huskygram, Singer * </td>
                                            <td className={'w-1/6 xl:w-auto'}>CSD</td>
                                        </tr>
                                        <tr>
                                            <td>Tajima</td>
                                            <td>DST</td>
                                        </tr>
                                        <tr>
                                            <td>ZSK *</td>
                                            <td>DSZ</td>
                                        </tr>
                                        <tr>
                                            <td>Melco</td>
                                            <td>EXP</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className={'col-12 lg:col-4'}>
                                    <table className="table-vertical">
                                        <tbody>

                                        <tr>
                                            <td>Husqvarna/Viking</td>
                                            <td className={'w-1/6 xl:w-auto'}>HUS</td>
                                        </tr>
                                        <tr>
                                            <td>Janome/Elna/Kenmore</td>
                                            <td>JEF</td>
                                        </tr>
                                        <tr>
                                            <td>Pfaff *</td>
                                            <td>PCS</td>
                                        </tr>
                                        <tr>
                                            <td>Babylock, Brother, Deco</td>
                                            <td>PEC, PES</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                                <div className={'col-12 lg:col-4'}>
                                    <table className="table-vertical">
                                        <tbody>
                                        <tr>
                                            <td>Janome, Elna, Kenmore</td>
                                            <td className={'w-1/6 xl:w-auto'}>SEW</td>
                                        </tr>
                                        <tr>
                                            <td>Happy *</td>
                                            <td>TAP</td>
                                        </tr>
                                        <tr>
                                            <td>Husqvarna Viking, Pfaff *</td>
                                            <td>VIP</td>
                                        </tr>
                                        <tr>
                                            <td>Compucon, Singer</td>
                                            <td>XXX</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                            <div className={'text-center mt-2'}>
                            * = coming soon
                            </div>
                        </div>
                    </div>
                </div>


                <div className="bg-blue-500 pt-12 pb-12 text-blue-50">
                    <div className="container mx-auto">

                        <div className="section-header">
                            <h2>And finally...</h2>
                        </div>

                        <div className={'row flex-wrap leading-normal text-lg'}>
                            <div className="col-12 md:col-4 mt-12">
                                <h4 className="text-normal mb-4 font-bold">Stitch simulation</h4>
                                <p>
                                    Preview how your design is stitched before sending it to your embroidery
                                    machine.
                                </p>
                                <ComingSoon/>
                            </div>
                            <div className="col-12 md:col-4 mt-12">
                                <h4 className="text-normal mb-4 font-bold">Realistic and stitch view</h4>
                                <p>
                                    See stitches in detail in the stitch view or preview your design using
                                    the realistic render mode.
                                </p>
                            </div>
                            <div className="col-12 md:col-4 mt-12">
                                <h4 className="text-normal mb-4 font-bold">Import designs </h4>
                                <p>
                                    You can import existing embroidery designs in the following formats:<br/>

                                    .ART, .CND,
                                    .CSD, .DST, .DSZ, .EMB, .EMD,
                                    .EXP, .HUS, .JAN, .JEF,
                                    .PCS, .PCM, .PES, .PHB,
                                    .PHC, .PHD, .SEW, .SHV,
                                    .TAP, .VIP, .VP3, .XXX
                                </p>
                                <ComingSoon/>
                            </div>
                        </div>

                    </div>

                </div>


                <Img fluid={this.props.data.footerImage.childImageSharp.fluid}/>

            </Layout>
        )
            ;
    }
}


export const pageQuery = graphql`
query {

    heroImage: file(relativePath: { eq: "parallax-2.jpg" }) {
      ...fluidImage
    },

    screenShotImage: file(relativePath: { eq: "organizer/screenshot-mac.png" }) {
      ...fluidImage
    },

    footerImage: file(relativePath: { eq: "threads.jpg" }) {
      ...fluidImage
    }
  }
`;
